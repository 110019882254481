
import {defineComponent, ref, reactive, toRefs, computed, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from 'element-plus';
import {ElLoading} from 'element-plus';
import axios from '@/api/http.js';
import footBar from "@/components/nav/inpixon/footBar.vue";

export default defineComponent({
  name: 'Login',
  components: {
    footBar
  },
  setup() {
    const route = useRouter(); // 第一步
    const data = reactive({
      // userName: 'leon.papkoff@inpixon.com',
      // userPwd: 'Leon1234!',
      userName: '',
      userPwd: '',
      showUserName: true,
      checkboxVal: false,
      showTip: false,//勾选提示
      tip: '',
      ssoSubffix: [],//有这些后缀的email会跳转到ssoLink
      ssoLink: ''//sso的登录网页
    });
    const showCheckBox = computed(() => {
      return data.showUserName;
    });
    const next = () => {
      data.userName = data.userName.trim();
      if (data.userName == '') {
        data.tip = 'Sorry, you\'re not currently registered for this event. Please click the register here hyperlinked text to the left to register.';
        data.showTip = true
        // ElMessageBox.confirm(
        //     'Please input your Username',
        //     {
        //       confirmButtonText: 'Yes',
        //       // cancelButtonText: "Cancel",
        //       type: 'warning',
        //     }
        // );
      } else {
        if (data.checkboxVal) {
          const loading = ElLoading.service({
            lock: true,
          })

          // let isSSO = false;
          // let userNameToSmall = data.userName.toLowerCase();
          // data.ssoSubffix.some(item => {
          //   let smallItem = item.toLowerCase();
          //   if (userNameToSmall.indexOf(smallItem) > -1) {
          //     isSSO = true;
          //     window.location.replace(data.ssoLink)
          //     return true
          //   }
          // })
          // if (isSSO) {
          //   return
          // }

          // let length = data.ssoSubffix.length;
          // for(let i = 0; i < length; i++){
          //   let item = data.ssoSubffix[i];
          //   let itmToSmall = item.toLowerCase();
          //   if(userNameToSmall.indexOf(itmToSmall)>-1){
          //     console.log("==== is sso=====",)
          //     return
          //   }
          // }
          axios.request({
            method: 'POST',
            url: 'm/check-email/',
            data: {
              email: data.userName,
              meetingId: 'mg9lr5rzd62to2hcidef11id'
            }
          }).then(res => {
            console.log('res:', res);
            console.log('res.data.result:', res.data.result);
            if (res.data.result == 'succeeded') {
              let isSSO = false;
              let userNameToSmall = data.userName.toLowerCase();
              data.ssoSubffix.some(item => {
                let smallItem = item.toLowerCase();
                if (userNameToSmall.indexOf(smallItem) > -1) {
                  isSSO = true;
                  window.location.replace(data.ssoLink)
                  return true
                }
              })
              if (isSSO) {
                return
              }
              data.showUserName = false;
              data.showTip = false;
            } else {
              data.tip = 'Thank you for registering. The Event will open on January 24, 2022 at 7:00am PST.'
              data.showTip = true;
            }
            loading.close();
          }).catch(err => {
            loading.close();
            // data.tip = err.response.data.error;
            console.log("====2222222222222222 =====",)
            data.tip = 'Thank you for registering. The Event will open on January 24, 2022 at 7:00am PST.'
            data.showTip = true
            // ElMessageBox.confirm(
            //     err.response.data.error,
            //     {
            //       confirmButtonText: 'Yes',
            //       // cancelButtonText: "Cancel",
            //       type: 'warning',
            //     }
            // );
          });
        } else {
          data.tip = "You must accept the Terms of Use to proceed";
          data.showTip = true
          // ElMessageBox.confirm(
          //     'You must accept the Terms of Use to proceed.',
          //     'Confirmation',
          //     {
          //       confirmButtonText: 'Yes',
          //       // cancelButtonText: "Cancel",
          //       type: 'warning',
          //     }
          // );
        }
      }
    };
    const login = () => {
      if (data.userName == '') {
        data.tip = 'Please input your password';
        data.showTip = true
        // ElMessageBox.confirm(
        //     'Please input your password',
        //     {
        //       confirmButtonText: 'Yes',
        //       // cancelButtonText: "Cancel",
        //       type: 'warning',
        //     }
        // );
      } else {
        const loading = ElLoading.service({
          lock: true,
        })
        axios.request({
          method: 'POST',
          url: '/m/authenticate/',
          data: {
            username: data.userName,
            password: data.userPwd,
          }
        }).then(res => {
          loading.close();
          if (res.data.result == 'succeeded') {
            localStorage.webToken = res.data.token;
            let campusInfo = res.data;
            localStorage.campusInfo = JSON.stringify(res.data);
            // route.push({name: 'Mec'});
            route.push({name: 'Home'});
          } else {
            console.log("==== result=====", res.data.error)
            data.tip = res.data.error;
            data.showTip = true
            // ElMessageBox.confirm(
            //     res.data.error,
            //     {
            //       confirmButtonText: 'Yes',
            //       // cancelButtonText: "Cancel",
            //       type: 'warning',
            //     }
            // );
          }
        }).catch(err => {
          console.log(err);
          loading.close();
          data.tip = err.response.data.error;
          data.showTip = true;
          // ElMessageBox.confirm(
          //     err.response.data.error,
          //     {
          //       confirmButtonText: 'Yes',
          //       // cancelButtonText: "Cancel",
          //       type: 'warning',
          //     }
          // );
        })
      }
    };
    const fun = () => {
    }
    const goLink = (link) => {
      window.open("https://www.inpixon.com/privacy-statement")
    }
    const goContactSup = (link) => {
      window.open("https://usersupport.inpixon.com/hc/en-us/requests/new?ticket_form_id=1900000368487&tf_1900002231027=InpixonSKO")
    }
    const getMConfig = () => {
      axios.request({
        method: 'GET',
        url: '/m/config',
      }).then(res => {
        console.log('cmsConfig!!!!!!!!:', res.data.ssoLink);
        data.ssoSubffix = res.data.ssoSubffix;
        data.ssoLink = res.data.ssoLink;
      }).catch(err => {
        console.log(err);
      })
    }
    getMConfig();
    onMounted(() => {
      window.addEventListener("keydown", (e) => {
        if (e.keyCode === 13) {
          if (data.showUserName) {
            next();
          } else {
            login();
          }
        }
      })
    });
    return {
      fun,
      ...toRefs(data),
      showCheckBox,
      next,
      login,
      goLink,
      goContactSup
    };
  },
});
